import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DaxHoverContext = createContext();

export const DaxHoverProvider = ({ children }) => {
  const [isDaxEnabled, setIsDaxEnabled] = useState(false);
  const [hoveredSection, setHoveredSection] = useState(null);
  const navigate = useNavigate();

  const handleDaxClick = (content) => {
    if (isDaxEnabled) {
      navigate('/inventory/dax/new', { state: { initialMessage: content } });
    }
  };

  return (
    <DaxHoverContext.Provider value={{
      isDaxEnabled,
      setIsDaxEnabled,
      hoveredSection,
      setHoveredSection,
      handleDaxClick
    }}>
      {children}
    </DaxHoverContext.Provider>
  );
};

export const useDaxHover = () => useContext(DaxHoverContext);