import React from 'react';
import { Send, Share } from 'lucide-react';

const DaxHoverOverlay = ({ onClick }) => (
  <div className="dax-hover-overlay" onClick={onClick}>
    <div className="dax-hover-content">
      <div className="dax-hover-text">
        <Share size={24} />
        <span>Send to DaxAI</span>
      </div>
    </div>
  </div>
);

export default DaxHoverOverlay;