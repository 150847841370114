// DocumentPreview.js
import React from 'react';
import { X } from 'lucide-react';

const DocumentPreview = ({ title, type, content, onClose }) => {
  return (
    <div className='document-preview-container-wrapper'>
      <div className="document-preview-container bg-gray-800 rounded-lg p-3 flex items-center gap-3 max-w-fit">
        <div className="document-icon">
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="document-svg-icon"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" fill="#0071db"/>
            <path d="M7 7h10M7 11h10M7 15h6" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </div>
        <div className="document-info flex flex-col">
          <span className="document-title text-white font-medium">{title}</span>
          <span className="document-type text-gray-400 text-sm">{type}</span>
        </div>
        {onClose && (
          <button 
            className="close-preview-btn text-gray-400 hover:text-white" 
            onClick={onClose}
            aria-label="Close preview"
          >
            <X size={16} />
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentPreview;