import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTimeoutId, setLoadingTimeoutId] = useState(null);
  const location = useLocation();

  const loadingProtectedRoutes = [
    '/inventory/dashboard',
    '/inventory/reports',
    '/inventory/profile'
  ];

  const setPageLoading = (loadingState) => {
    if (!loadingProtectedRoutes.includes(location.pathname)) {
      setIsLoading(false);
      return;
    }

    if (loadingTimeoutId) {
      clearTimeout(loadingTimeoutId);
    }

    if (loadingState) {
      setIsLoading(true);
    } else {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 100);
      setLoadingTimeoutId(timeoutId);
    }

    const event = new CustomEvent('pageLoadingChange', {
      detail: {
        page: location.pathname,
        isLoading: loadingState
      }
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    return () => {
      if (loadingTimeoutId) {
        clearTimeout(loadingTimeoutId);
      }
    };
  }, [loadingTimeoutId]);

  return (
    <LoadingContext.Provider value={{ isLoading, setPageLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
