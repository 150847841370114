import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, addDoc, onSnapshot, orderBy, serverTimestamp, updateDoc, doc, writeBatch, increment } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Search, X, Send, Check, AlertCircle, CheckCheck, Paperclip, Image, FileText } from 'lucide-react';
import CommunicationSidebar from '../CommunicationSidebar';
import "./Messenger.css";

const MessageType = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document'
};


const MessageStatus = {
  SENDING: 'sending',
  DELIVERED: 'delivered',
  NOT_DELIVERED: 'not_delivered',
  NOT_SEEN: 'not_seen',
  SEEN: 'seen'
};


const Messenger = () => {
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [searchEmail, setSearchEmail] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [currentChatUser, setCurrentChatUser] = useState(null);
  const { chatId } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const [showAttachmentOptions, setShowAttachmentOptions] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const storage = getStorage();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch current user data
  useEffect(() => {
    if (!auth.currentUser) return;

    const fetchCurrentUserData = async () => {
      const storesRef = collection(db, 'stores');
      const q = query(storesRef, where('userId', '==', auth.currentUser.uid));
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        setCurrentUserData(snapshot.docs[0].data());
      }
    };

    fetchCurrentUserData();
  }, [auth.currentUser, db]);

  // Fetch chats
  useEffect(() => {
    if (!auth.currentUser || !currentUserData) return;

    const fetchChats = async () => {
      const chatsRef = collection(db, `stores/${currentUserData.modifiedBrandName}/messenger`);
      const q = query(chatsRef, orderBy('lastMessageTime', 'desc'));
      
      return onSnapshot(q, async (snapshot) => {
        const chatsData = [];
        for (const doc of snapshot.docs) {
          const chatData = doc.data();
          const otherParticipant = chatData.participants.find(
            p => p.userId !== auth.currentUser.uid
          );
          
          if (otherParticipant) {
            const otherUserData = await fetchUserData(otherParticipant.userId);
            chatsData.push({
              id: doc.id,
              ...chatData,
              otherUserData
            });
          }
        }
        setChats(chatsData);

        if (chatId && chatId !== 'new') {
          const currentChat = chatsData.find(chat => chat.id === chatId);
          if (currentChat) {
            setCurrentChatUser(currentChat.otherUserData);
          }
        }
      });
    };

    const unsubscribe = fetchChats();
    return () => unsubscribe;
  }, [auth.currentUser, currentUserData, db, chatId]);

  // Fetch user data helper
  const fetchUserData = async (userId) => {
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs[0]?.data();
  };

  // Fetch and listen to messages
  useEffect(() => {
    if (!chatId || chatId === 'new' || !currentUserData || !auth.currentUser) return;

    const messagesRef = collection(db, `stores/${currentUserData.modifiedBrandName}/messenger/${chatId}/messages`);
    const q = query(messagesRef, orderBy('timestamp', 'asc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(messagesData);
    }, { includeMetadataChanges: true });

    const markMessagesAsDelivered = async () => {
      const undeliveredQuery = query(
        messagesRef,
        where('senderId', '!=', auth.currentUser.uid),
        where('delivered', '==', false)
      );
      
      const undeliveredSnapshot = await getDocs(undeliveredQuery);
      
      if (!undeliveredSnapshot.empty) {
        const batch = writeBatch(db);
        undeliveredSnapshot.docs.forEach((doc) => {
          batch.update(doc.ref, {
            delivered: true,
            status: MessageStatus.NOT_SEEN
          });
        });
        await batch.commit();
      }
    };

    markMessagesAsDelivered();
    return () => unsubscribe();
  }, [chatId, currentUserData, db, auth.currentUser]);


  // Handle message seen status
  useEffect(() => {
    if (!chatId || chatId === 'new' || !currentUserData || !auth.currentUser) return;
  
    const handleMessageSeen = async () => {
      const currentChat = chats.find(chat => chat.id === chatId);
      if (!currentChat) return;
  
      const messagesRef = collection(db, `stores/${currentUserData.modifiedBrandName}/messenger/${chatId}/messages`);
      
      const unseenQuery = query(
        messagesRef,
        where('senderId', '!=', auth.currentUser.uid),
        where('delivered', '==', true),
        where('seen', '==', false)
      );
  
      const unseenSnapshot = await getDocs(unseenQuery);
      
      if (!unseenSnapshot.empty) {
        const batch = writeBatch(db);
        const recipientModifiedBrandName = currentChat.participants.find(
          p => p.userId !== auth.currentUser.uid
        )?.modifiedBrandName;
  
        // Update the unread count atomically
        const chatRef = doc(db, `stores/${currentUserData.modifiedBrandName}/messenger`, chatId);
        batch.update(chatRef, {
          unreadMessageCount: 0
        });
  
        unseenSnapshot.docs.forEach((document) => {
          batch.update(document.ref, {
            seen: true,
            status: MessageStatus.SEEN
          });
  
          if (recipientModifiedBrandName) {
            const senderMessagePath = `stores/${recipientModifiedBrandName}/messenger/${chatId}/messages/${document.id}`;
            const senderMessageRef = doc(db, senderMessagePath);
            batch.update(senderMessageRef, {
              seen: true,
              status: MessageStatus.SEEN
            });
          }
        });
  
        await batch.commit();
      }
    };
          
    // Call handleMessageSeen when the chat becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && document.hasFocus()) {
        handleMessageSeen();
      }
    };
  
    const handleFocus = () => {
      if (document.visibilityState === 'visible') {
        handleMessageSeen();
      }
    };
  
  
    handleMessageSeen();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [chatId, currentUserData, db, auth.currentUser, chats]);    


  // Search users
  const searchUsers = async (searchTerm) => {
    if (!searchTerm) {
      setUsers([]);
      return;
    }
    
    const storesRef = collection(db, 'stores');
    const searchTermLower = searchTerm.toLowerCase();
    const q = query(
      storesRef,
      where('email', '>=', searchTermLower),
      where('email', '<=', searchTermLower + '\uf8ff')
    );
    
    const snapshot = await getDocs(q);
    const usersData = snapshot.docs
      .map(doc => doc.data())
      .filter(user => user.userId !== auth.currentUser.uid);
    
    setUsers(usersData);
  };

  // Create new chat
  const createChat = async (recipientData) => {
    if (!auth.currentUser || !currentUserData) return;
  
    const existingChatsQuery = query(
      collection(db, `stores/${currentUserData.modifiedBrandName}/messenger`),
      where('participants', 'array-contains', {
        userId: auth.currentUser.uid,
        modifiedBrandName: currentUserData.modifiedBrandName
      })
    );
  
    const existingChats = await getDocs(existingChatsQuery);
    const existingChat = existingChats.docs.find(doc => 
      doc.data().participants.some(p => p.userId === recipientData.userId)
    );
  
    if (existingChat) {
      navigate(`/inventory/messenger/${existingChat.id}`);
      return;
    }

    const chatData = {
      participants: [
        {
          userId: auth.currentUser.uid,
          modifiedBrandName: currentUserData.modifiedBrandName
        },
        {
          userId: recipientData.userId,
          modifiedBrandName: recipientData.modifiedBrandName
        }
      ],
      participantEmails: [currentUserData.email, recipientData.email],
      createdAt: serverTimestamp(),
      lastMessageTime: serverTimestamp(),
      unreadMessageCount: 0
    };


    try {
      const batch = writeBatch(db);
      
      const senderChatRef = collection(db, `stores/${currentUserData.modifiedBrandName}/messenger`);
      const newChatRef = doc(senderChatRef);
      batch.set(newChatRef, chatData);
      
      const recipientChatRef = doc(db, `stores/${recipientData.modifiedBrandName}/messenger`, newChatRef.id);
      batch.set(recipientChatRef, chatData);
      
      await batch.commit();
    
      navigate(`/inventory/messenger/${newChatRef.id}`);
      setSelectedUser(null);
      setUsers([]);
      setIsSearchOpen(false);
      setCurrentChatUser(recipientData);
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };



  const handleAttachmentClick = () => {
    setShowAttachmentOptions(!showAttachmentOptions);
  };

  const handleFileSelect = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    setShowAttachmentOptions(false);
    setUploading(true);

    try {
      const messageId = Date.now().toString();
      const fileExtension = file.name.split('.').pop();
      const storageRef = ref(storage, `messages/${currentUserData.modifiedBrandName}/${chatId}/${messageId}.${fileExtension}`);
      
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          
          let messageType;
          if (type === 'media') {
            messageType = file.type.startsWith('image/') ? MessageType.IMAGE : MessageType.VIDEO;
          } else {
            messageType = MessageType.DOCUMENT;
          }

          const messageData = {
            senderId: auth.currentUser.uid,
            senderEmail: currentUserData.email,
            timestamp: serverTimestamp(),
            status: MessageStatus.SENDING,
            delivered: false,
            seen: false,
            type: messageType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type
          };

          await sendMessage(null, messageData);
          setUploading(false);
          setUploadProgress(0);
        }
      );
    } catch (error) {
      console.error("File upload error:", error);
      setUploading(false);
    }
  };

  // Send message
  const sendMessage = async (e, attachmentData = null) => {
    if (e) e.preventDefault();
    if (!attachmentData && !message.trim()) return;
    if (!chatId || !auth.currentUser || !currentUserData) return;
  
    const currentChat = chats.find(chat => chat.id === chatId);
    if (!currentChat) return;
  
    let senderMessagesRef;
  
    const messageData = attachmentData || {
      text: message.trim(),
      type: MessageType.TEXT,
      senderId: auth.currentUser.uid,
      senderEmail: currentUserData.email,
      timestamp: serverTimestamp(),
      status: MessageStatus.SENDING,
      delivered: false,
      seen: false
    };
  
    const recipient = currentChat.participants.find(p => p.userId !== auth.currentUser.uid);
  if (!recipient) return;

  try {
    const batch = writeBatch(db);

    senderMessagesRef = doc(collection(db, `stores/${currentUserData.modifiedBrandName}/messenger/${chatId}/messages`));
    batch.set(senderMessagesRef, messageData);

    const senderChatRef = doc(db, `stores/${currentUserData.modifiedBrandName}/messenger`, chatId);
    batch.update(senderChatRef, {
      lastMessageTime: serverTimestamp(),
      lastMessage: messageData.type === MessageType.TEXT ? messageData.text : `Sent a ${messageData.type.toLowerCase()}`
    });

    const recipientMessagesRef = doc(
      collection(db, `stores/${recipient.modifiedBrandName}/messenger/${chatId}/messages`),
      senderMessagesRef.id
    );
    
    const recipientChatRef = doc(db, `stores/${recipient.modifiedBrandName}/messenger`, chatId);
    
    const recipientChatUpdate = {
      lastMessageTime: serverTimestamp(),
      lastMessage: messageData.type === MessageType.TEXT ? messageData.text : `Sent a ${messageData.type.toLowerCase()}`
    };


     // Only increment unread count for the recipient
     if (recipient.userId !== auth.currentUser.uid) {
      recipientChatUpdate.unreadMessageCount = increment(1);
    }

    batch.set(recipientMessagesRef, {
      ...messageData,
      status: MessageStatus.NOT_SEEN,
      delivered: true
    });

    batch.update(recipientChatRef, recipientChatUpdate);

    await batch.commit();

    await updateDoc(senderMessagesRef, {
      status: MessageStatus.NOT_SEEN,
      delivered: true
    });
  
    setMessage('');
  } catch (error) {
    console.error("Error sending message:", error);
    if (senderMessagesRef) {
      await updateDoc(senderMessagesRef, {
        status: MessageStatus.NOT_DELIVERED,
        delivered: false
      });
    }
  }
};


  const MessageStatusIndicator = ({ status }) => {
    switch (status) {
      case MessageStatus.SENDING:
        return <Check size={14} color="#a8a8a8" />;
      case MessageStatus.DELIVERED:
      case MessageStatus.NOT_SEEN:
        return (
          <div className="double-check">
            <Check size={14} color="#a8a8a8" />
            <Check size={14} color="#a8a8a8" className="second-check" />
          </div>
        );
      case MessageStatus.SEEN:
        return (
          <div className="double-check">
            <Check size={14} color="#075ede" />
            <Check size={14} color="#075ede" className="second-check" />
          </div>
        );
      case MessageStatus.NOT_DELIVERED:
        return <AlertCircle size={14} color="#700101" />;
      default:
        return null;
    }
  };
  

  return (
    <div className="messenger-container">
      <CommunicationSidebar />
      <div className="messenger-content">
        {chatId === 'new' || isSearchOpen ? (
          <div className="new-chat-container">
            <div className="new-chat-header">
              <h2>Create New Chat</h2>
              <button onClick={() => setIsSearchOpen(false)} className="close-messenger-search-button">
                <X size={20} />
              </button>
            </div>
            <div className="messenger-search-user-container">
            <div className="messenger-search-user-container-wrapper">
                
                <Search className="messenger-search-icon" />
                <input
                  type="text"
                  placeholder="Search users by email..."
                  value={searchEmail}
                  onChange={(e) => {
                    setSearchEmail(e.target.value);
                    searchUsers(e.target.value);
                  }}
                  className="messenger-search-user-input"
                  autoFocus
                />
                  </div>

              {users.length > 0 && (
                <div className="messenger-users-list">
                  {users.map(user => (
                    <div key={user.userId} className="messenger-user-item">
                      <div className="messenger-user-avatar">
                        {user.email.charAt(0).toUpperCase()}
                      </div>
                      <div className="messenger-user-details">
                        <span className="messenger-user-email">{user.email}</span>
                        <button 
                          onClick={() => createChat(user)}
                          className="create-chat-btn"
                        >
                          Create Chat
                        </button>
                      </div>
                    </div>
                  ))}

                  
                </div>
              )}
              {searchEmail && users.length === 0 && (
                <div className="no-results">
                  No users found matching "{searchEmail}"
                </div>
              )}
            </div>
          </div>
        ) : chatId ? (
          <div className="chat-window">
          <div className="chat-header">
            {currentChatUser ? (
              <div className="chat-recipient-info">
                <div className="chat-recipient-avatar">
                  {currentChatUser.email.charAt(0).toUpperCase()}
                </div>
                <div className="chat-recipient-details">
                  <div className="chat-recipient-email">{currentChatUser.email}</div>
                </div>
              </div>
            ) : (
              <div className="loading-recipient">Loading...</div>
            )}
          </div>
          <div className="messages-outer-container">
            <div className="messages-container" ref={messagesContainerRef}>
            {messages.map(msg => (
  <div
    key={msg.id}
    className={`message-wrapper ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}
  >
    {msg.senderId !== auth.currentUser.uid && (
      <div className="message-avatar">
        {msg.senderEmail?.charAt(0).toUpperCase()}
      </div>
    )}
    <div className="message-bubble-container">
      <div className={`message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
        <div className="messenger-message-content">{msg.text}</div>
        <div className="message-footer">
        <div className="message-footer-inside">
          
          <div className="message-meta">
            <span className="message-time">
              {msg.timestamp?.toDate().toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
              })}
            </span>
            {msg.senderId === auth.currentUser.uid && (
              <div className="message-status">
                <MessageStatusIndicator status={msg.status} />
              </div>
            )}
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
))}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="message-input-wrapper">
            <form onSubmit={sendMessage} className="message-input-form">
            <div className="message-input-container-wrapper">
              <div className='messenger-paperclip-icon-wrapper' >
              <Paperclip className='messenger-paperclip-icon' size={22}/>
              </div>
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message..."
                className="message-input"
              />

              <button type="submit" className="messenger-send-button" disabled={!message.trim()}>
                <Send size={20} />
              </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
          <div className="chats-list-container">
            <div className="chats-header">
              <h2>Recent Chats</h2>
              <button
                onClick={() => navigate('/inventory/messenger/new')}
                className="create-chat-button"
              >
                Create Chat
              </button>
            </div>
            <div className="chats-list">
              {chats.map(chat => (
                <div
                  key={chat.id}
                  onClick={() => navigate(`/inventory/messenger/${chat.id}`)}
                  className="chat-item"
                >
                  <div className="chat-avatar">
                    {chat.otherUserData?.email.charAt(0).toUpperCase()}
                  </div>
                  <div className="chat-preview">
                    <div className="chat-info">
                      <span className="chat-participant">{chat.otherUserData?.email}</span>
                      {chat.unreadMessageCount > 0 && (
                        <span className="chat-unread-count">
                          {chat.unreadMessageCount > 99 ? '99+' : chat.unreadMessageCount}
                        </span>
                      )}
                      <span className="chat-time">
                        {chat.lastMessageTime?.toDate().toLocaleDateString()}
                      </span>
                    </div>
                    {chat.lastMessage && (
                      <div className="chat-last-message">{chat.lastMessage}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Messenger;