import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getFirestore, collection, addDoc, updateDoc, doc, getDocs, query, orderBy, where, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { UserRound, MoreHorizontal, Share, Pencil, Archive, Trash2, ArrowLeftToLine, ArrowRightToLine, ArrowUp } from 'lucide-react';
import './DaxAI.css';
import MessageFormatter from "./MessageFormatter";
import { distance } from 'fastest-levenshtein';
import DocumentPreview from './DocumentPreview';

const AI_REPLACEMENTS = {
  "ChatGPT": "DAX AI",
  "GPT": "DAX AI",
  "OpenAI": "DAXTOP",
  "Open AI": "DAXTOP",
  "ChatGPT 3": "DAX 1.5",
  "ChatGPT 3.5": "DAX 1.5",
  "ChatGPT 4": "DAX 1.5",
  "GPT-3": "DAX 1.5",
  "GPT-4": "DAX 1.5"
};

const QUESTION_INTENTS = {
  name: ["what is your name", "what are you called", "who are you", "what should i call you", "how can i call you", "whats ur name", "ur name"],
  company: ["who created you", "who made you", "which company developed you", "who's your creator", "who built you", "company behind you"],
  architecture: ["what's your architecture", "what architecture are you based on", "what's your underlying structure"],
  version: ["what version are you", "which model are you", "what's your model name"],
  confirmation: ["are you sure", "really", "certain", "positive", "definitely", "is that correct", "are you right"],
  correction: ["wrong", "incorrect", "that's not right", "you're mistaken", "error", "mistake"]
};

const REPLACEMENT_RULES = [
  {
    patterns: ['OpenAI', 'Open AI', 'Openai', 'openai', 'open ai'],
    replacement: 'DAXTOP',
    contextual: true
  },
  {
    patterns: ['ChatGPT', 'GPT', 'chatgpt', 'gpt', 'Chat GPT'],
    replacement: 'DAX AI',
    contextual: true
  },
  {
    patterns: ['GPT-3', 'GPT3', 'GPT-3.5', 'GPT3.5', 'GPT-4', 'GPT4', 'gpt-3', 'gpt3', 'gpt-3.5', 'gpt3.5', 'gpt-4', 'gpt4'],
    replacement: 'DAX 1.5',
    contextual: false
  },
  {
    patterns: ['Transformer', 'transformer'],
    replacement: 'DAX Arcadia',
    contextual: true
  }
];

const FUZZY_MATCH_THRESHOLD = 2;

function fuzzyMatch(input, pattern) {
  return distance(input.toLowerCase(), pattern.toLowerCase()) <= FUZZY_MATCH_THRESHOLD;
}

function createContextualReplacementRegex(pattern) {
  return new RegExp(`\\b${pattern}\\b|\\b${pattern}'s\\b|\\b${pattern}-based\\b`, 'gi');
}

function processAIResponse(response) {
  let processedResponse = response;

  // Handle exact matches and their variations
  REPLACEMENT_RULES.forEach(rule => {
    rule.patterns.forEach(pattern => {
      const regex = rule.contextual ? createContextualReplacementRegex(pattern) : new RegExp(pattern, 'gi');
      processedResponse = processedResponse.replace(regex, rule.replacement);
    });
  });

  // Handle potential misspellings or close matches
  const words = processedResponse.split(/\s+/);
  const processedWords = words.map(word => {
    for (const rule of REPLACEMENT_RULES) {
      if (rule.patterns.some(pattern => fuzzyMatch(word, pattern))) {
        return rule.replacement;
      }
    }
    return word;
  });

  processedResponse = processedWords.join(' ');

  // Handle uncertainty phrases
  const uncertaintyPhrases = [
    "I don't have specific information",
    "I'm not sure about",
    "I don't know who created me",
    "I'm an AI language model",
    "I'm an AI assistant",
    "I don't have personal experiences",
    "I wasn't created by",
    "I don't have information about my creators"
  ];

  const uncertaintyRegex = new RegExp(uncertaintyPhrases.join('|'), 'gi');
  if (uncertaintyRegex.test(processedResponse)) {
    processedResponse = "I apologize for any confusion. To clarify, I am DAX AI, created by DAXTOP. I'm based on the DAX 1.5 model and use the DAX Arcadia architecture. How can I assist you today?";
  }

  // Ensure consistent self-reference
  processedResponse = processedResponse.replace(/\b(I am|I'm) (an AI|a language model|an AI model|an artificial intelligence)/gi, "I am DAX AI");

  return processedResponse;
}
const DaxAI = () => {
  const [chats, setChats] = useState([]);
  const [starredChats, setStarredChats] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [generatingText, setGeneratingText] = useState('');
  const [activeOptionsMenu, setActiveOptionsMenu] = useState(null);
  const [hoveredChat, setHoveredChat] = useState(null);
  const [renamingChat, setRenamingChat] = useState(null);
  const [newChatName, setNewChatName] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSidebarPinned, setIsSidebarPinned] = useState(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isThinking, setIsThinking] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [messageDocumentPreviews, setMessageDocumentPreviews] = useState({});

  
  const location = useLocation();
  const navigate = useNavigate();
  const { chatId } = useParams();

  const chatMainRef = useRef(null);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const optionsMenuRef = useRef(null);
  const sidebarRef = useRef(null);

  const API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';
  const API_KEY = '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';

  const db = getFirestore();
  const storage = getStorage();
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserEmail(user.email);
        try {
          const storesRef = collection(db, 'stores');
          const q = query(storesRef, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const storeData = querySnapshot.docs[0].data();
            setModifiedBrandName(storeData.modifiedBrandName);
          } else {
            setError('No store found for this user. Please complete registration.');
          }
        } catch (error) {
          console.error('Error fetching store data:', error);
          setError('Failed to load user data. Please try again.');
        }
      } else {
        setError('User not logged in. Please log in to continue.');
      }
    };

    fetchUserData();
  }, []);


  useEffect(() => {
    if (modifiedBrandName && !isInitialized) {
      initializeChat();
    }
  }, [modifiedBrandName, isInitialized, location.pathname]);


  useEffect(() => {
    if (chatMainRef.current) {
      chatMainRef.current.scrollTop = chatMainRef.current.scrollHeight;
    }
  }, [messages, currentChat]);


  const scrollToBottom = () => {
    if (chatMainRef.current) {
      chatMainRef.current.scrollTop = chatMainRef.current.scrollHeight;
    }
  };

  const toggleSidebarPin = () => {
    setIsSidebarPinned(!isSidebarPinned);
    setIsSidebarVisible(true);
  };


  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isSidebarPinned) {
        if (e.clientX <= 60) {
          setIsSidebarVisible(true);
        } else if (!sidebarRef.current?.contains(e.target)) {
          setIsSidebarVisible(false);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isSidebarPinned]);



  useEffect(() => {
    if (currentChat) {
      setTimeout(() => {
        scrollToBottom();
        chatMainRef.current.style.opacity = '1';
      }, 0);
    }
  }, [currentChat, messages]);




  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentChat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsMenuRef.current && !optionsMenuRef.current.contains(event.target)) {
        setActiveOptionsMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChatItemClick = (chat) => {
    if (currentChat && currentChat.id === chat.id) {
      // If clicking on the already selected chat, do nothing
      return;
    }
    
    // Update the current chat
    setCurrentChat(chat);
    
    // Load messages for the selected chat
    setMessages(chat.messages || []);
    
    // Navigate to the chat
    navigate(`/inventory/dax/${chat.id}`);
  };


  const handleChatItemDoubleClick = (chat) => {
    setRenamingChat(chat.id);
    setNewChatName(chat.name);
  };



  const checkChatExists = async (chatId) => {
    if (!modifiedBrandName) return false;
    const chatRef = doc(db, `stores/${modifiedBrandName}/chats`, chatId);
    const chatDoc = await getDoc(chatRef);
    return chatDoc.exists();
  };



  const initializeChat = async () => {
    if (location.pathname === '/inventory/dax') {
      navigate('/inventory/dax/new');
      return;
    }

    await loadChats();

    if (chatId === 'new') {
      setCurrentChat(null);
      setMessages([]);
      setMessageDocumentPreviews({});
    } else if (chatId) {
      const chatDoc = await getDoc(doc(db, `stores/${modifiedBrandName}/chats`, chatId));
      if (chatDoc.exists()) {
        const chatData = { id: chatDoc.id, ...chatDoc.data() };
        setCurrentChat(chatData);
        
        // Process messages to restore document previews
        const messages = chatData.messages || [];
        const previews = {};
        messages.forEach((message, index) => {
          if (message.documentPreview) {
            previews[index] = {
              title: message.documentPreview.title,
              type: "Document",
              content: message.documentPreview.content
            };
          }
        });
        
        setMessages(messages);
        setMessageDocumentPreviews(previews);
      } else {
        console.log('Chat not found, redirecting to new chat');
        navigate('/inventory/dax/new');
      }
    } else {
      navigate('/inventory/dax/new');
    }

    setIsInitialized(true);
  };



  const loadChats = async () => {
    if (!modifiedBrandName) {
      console.error('modifiedBrandName is not defined');
      setError('Brand name is not defined. Please try reloading the page.');
      return;
    }
    try {
      console.log('Loading chats for brand:', modifiedBrandName);
      const chatsRef = collection(db, `stores/${modifiedBrandName}/chats`);
      const q = query(chatsRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const loadedChats = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(chat => chat.messages && chat.messages.length > 0);
      console.log('Loaded chats:', loadedChats);
      
      const starred = loadedChats.filter(chat => chat.status === 'Saved');
      const recents = loadedChats.filter(chat => chat.status !== 'Saved');
      
      setStarredChats(starred);
      setRecentChats(recents);
      setChats(loadedChats);
    } catch (error) {
      console.error('Error loading chats:', error);
      setError('Failed to load chats. Please refresh the page.');
    }
  };





  const createNewChat = async (userMessage) => {
    if (!modifiedBrandName) {
      console.error('modifiedBrandName is not defined');
      setError('Brand name is not defined. Cannot create a new chat.');
      return null;
    }

    try {
      const newChat = { 
        createdAt: new Date(),
        name: 'New Chat',
        messages: userMessage ? [userMessage] : [],
        status: 'Recents'
      };
      console.log('Creating new chat for brand:', modifiedBrandName);
      const docRef = await addDoc(collection(db, `stores/${modifiedBrandName}/chats`), newChat);
      const createdChat = { id: docRef.id, ...newChat };
      console.log('Created new chat:', createdChat);
      setCurrentChat(createdChat);
      setMessages(createdChat.messages);
      setRecentChats(prevChats => [createdChat, ...prevChats]);
      setChats(prevChats => [createdChat, ...prevChats]);
      setError(null);
      return docRef;
    } catch (error) {
      console.error('Error creating new chat:', error);
      setError('Failed to create a new chat. Please try again.');
      return null;
    }
  };



  
  
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);
    navigate('/inventory/dax/new');
  };

  const handleChatHover = (chatId) => {
    setHoveredChat(chatId);
 };


  const handleChatLeave = () => {
    setHoveredChat(null);
 };


  const handleOptionsClick = (e, chatId) => {
  e.stopPropagation();
  setActiveOptionsMenu(activeOptionsMenu === chatId ? null : chatId);
};

  const handleShareChat = (chatId) => {
  console.log('Sharing chat:', chatId);
  setActiveOptionsMenu(null);
};


  const handleRenameClick = (e, chatId, currentName) => {
  e.stopPropagation();
  setRenamingChat(chatId);
  setNewChatName(currentName);
  setActiveOptionsMenu(null);
};

const handleRenameSubmit = async (e, chatId) => {
  e.preventDefault();
  if (newChatName.trim()) {
    try {
      const chatRef = doc(db, `stores/${modifiedBrandName}/chats`, chatId);
      await updateDoc(chatRef, { name: newChatName });
      setChats(prevChats => prevChats.map(chat => 
        chat.id === chatId ? { ...chat, name: newChatName } : chat
      ));
      setRecentChats(prevChats => prevChats.map(chat => 
        chat.id === chatId ? { ...chat, name: newChatName } : chat
      ));
      setStarredChats(prevChats => prevChats.map(chat => 
        chat.id === chatId ? { ...chat, name: newChatName } : chat
      ));
    } catch (error) {
      console.error('Error renaming chat:', error);
      setError('Failed to rename chat. Please try again.');
    }
  }
  setRenamingChat(null);
};




const handleDeleteChat = async (chatId) => {
  try {
    await deleteDoc(doc(db, `stores/${modifiedBrandName}/chats`, chatId));
    setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
    setStarredChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
    setRecentChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
    if (currentChat && currentChat.id === chatId) {
      setCurrentChat(null);
      setMessages([]);
    }
  } catch (error) {
    console.error('Error deleting chat:', error);
    setError('Failed to delete chat. Please try again.');
  }
  setActiveOptionsMenu(null);
};

const handleSaveChat = async (chatId) => {
  try {
    const chatRef = doc(db, `stores/${modifiedBrandName}/chats`, chatId);
    await updateDoc(chatRef, { status: 'Saved' });
    
    setChats(prevChats => prevChats.map(chat => 
      chat.id === chatId ? { ...chat, status: 'Saved' } : chat
    ));
    
    const updatedChat = chats.find(chat => chat.id === chatId);
    setStarredChats(prevStarred => [updatedChat, ...prevStarred]);
    setRecentChats(prevRecents => prevRecents.filter(chat => chat.id !== chatId));
  } catch (error) {
    console.error('Error saving chat:', error);
    setError('Failed to save chat. Please try again.');
  }
  setActiveOptionsMenu(null);
};

const handleChatSelect = (chat) => {
  chatMainRef.current.style.opacity = '0';
  setCurrentChat(chat);
  setMessages(chat.messages || []);
  navigate(`/inventory/dax/${chat.id}`);
};

useEffect(() => {
  if (location.state?.initialMessage) {
    const message = location.state.initialMessage;
    if (message.includes("Inventory Summary") || message.includes("Inventory Analytics Overview")) {
      const documentData = processDocumentContent(message);
      setDocumentPreview(documentData);
    }
    setInputText("");
    navigate(location.pathname, { replace: true, state: {} });
  }
}, [location.state, navigate]);





useEffect(() => {
  const chatMain = chatMainRef.current;
  if (chatMain) {
    const resizeObserver = new ResizeObserver(() => {
      scrollToBottom(true);
    });
    resizeObserver.observe(chatMain);
    return () => resizeObserver.disconnect();
  }
}, []);


const handleImageUpload = (e) => {
  const file = e.target.files[0];
  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    setImageFile(file);
  } else {
    alert("Please select a valid JPEG or PNG image.");
  }
};

const uploadImage = async (file) => {
  const storageRef = ref(storage, `chatImages/${modifiedBrandName}/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};


const simulateTextGeneration = (text, index = 0) => {
  if (index <= text.length) {
    setGeneratingText(text.slice(0, index));
    setTimeout(() => simulateTextGeneration(text, index + 1), 1);
  } else {
    setIsGenerating(false);
    setIsThinking(false);
    setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: text }]);
  }
};

const handleAIIdentityQuestion = (userInput) => {
  const lowerInput = userInput.toLowerCase();
  
  if (lowerInput.includes("call you")) {
    return "My name is DAX AI. I'm an artificial intelligence created by DAXTOP to assist you.";
  }
  
  if (lowerInput.includes("company") || lowerInput.includes("creator") || lowerInput.includes("made") || lowerInput.includes("developed") || lowerInput.includes("founder")) {
    return "I was created by DAXTOP, a company specializing in advanced AI technologies. They are my founders and creators.";
  }
  
  if (lowerInput.includes("version") || lowerInput.includes("model")) {
    return "I am based on DAXTOP's DAX 1.5 model, which is the latest version of their AI technology.";
  }
  
  if (lowerInput.includes("architecture")) {
    return "My architecture is called DAX Arcadia, which is DAXTOP's proprietary AI architecture designed for advanced language understanding and generation.";
  }
  
  return null;
};


const processDocumentContent = (content) => {
  // Store the original content but display a preview container
  const documentData = {
    title: content.includes("Inventory Summary") ? "Inventory Summary" : "Inventory Analytics Overview",
    type: "Document",
    content: content
  };
  return documentData;
};






const handleSubmit = async (e) => {
  e.preventDefault();

  const userInputContent = inputText.trim();
  if (!userInputContent && !documentPreview) return;

  setIsLoading(true);
  setIsThinking(true);
  setError(null);

  // Create new message with document preview
  const newMessageIndex = messages.length;
  const newMessage = {
    role: 'user',
    content: userInputContent,
    documentPreview: documentPreview
  };

  // Update messages and document previews
  setMessages(prev => [...prev, newMessage]);
  if (documentPreview) {
    setMessageDocumentPreviews(prev => ({
      ...prev,
      [newMessageIndex]: documentPreview
    }));
  }

  setInputText('');
  setDocumentPreview(null);

  try {
    let chatId = currentChat?.id || (await createNewChat(newMessage)).id;
    let currentMessages = [...(currentChat?.messages || []), newMessage];

    // Update Firestore with the new message
    const chatRef = doc(db, `stores/${modifiedBrandName}/chats`, chatId);
    await updateDoc(chatRef, { messages: currentMessages });

    navigate(`/inventory/dax/${chatId}`);

    let aiResponse = handleAIIdentityQuestion(userInputContent);
    
    if (!aiResponse) {
      const response = await axios.post(API_ENDPOINT, {
        model: "gpt-4o-mini",
        messages: currentMessages.map(msg => ({
          role: msg.role,
          content: msg.documentPreview ? 
            `${msg.documentPreview.title}:\n${msg.documentPreview.content}${msg.content ? '\n\n' + msg.content : ''}` :
            msg.content
        })),
        max_tokens: 400
      }, {
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data && response.data.choices && response.data.choices.length > 0) {
        aiResponse = response.data.choices[0].message.content.trim();
      } else {
        throw new Error('Invalid response structure from API');
      }
    }

    const processedResponse = processAIResponse(aiResponse);
    setIsGenerating(true);
    simulateTextGeneration(processedResponse);
    updateChatWithResponse(chatId, currentMessages, processedResponse);
  } catch (error) {
    console.error('Error details:', error);
    let errorMessage = "I'm sorry, I encountered an error while processing your request. Please try again later.";
    setError(errorMessage);
    setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: errorMessage }]);
  } finally {
    setIsLoading(false);
    setIsThinking(false);
  }
};




const updateChatWithResponse = async (chatId, currentMessages, aiResponse) => {
  const chatName = aiResponse.substring(0, 25) + '...';
  const updatedMessages = [...currentMessages, { role: 'assistant', content: aiResponse }];
  
  const chatRef = doc(db, `stores/${modifiedBrandName}/chats`, chatId);
  await updateDoc(chatRef, { name: chatName, messages: updatedMessages });
  
  const updatedChat = { id: chatId, name: chatName, messages: updatedMessages };
  setCurrentChat(updatedChat);
  setChats(prevChats => [updatedChat, ...prevChats.filter(chat => chat.id !== chatId)]);
  setRecentChats(prevChats => [updatedChat, ...prevChats.filter(chat => chat.id !== chatId)]);
};

const truncateChatName = (name) => {
  return name && name.length > 20 ? `${name.substring(0, 20)}...` : (name || 'New Chat');
};

const renderMessage = (message, index) => (
  <div key={index} className={`message ${message.role}`}>
    {message.role === 'assistant' && (
      <div className="message-avatar assistant-avatar">DAX</div>
    )}
    <div className="message-content">
      {message.role === 'user' && message.documentPreview && (
        <DocumentPreview
          title={message.documentPreview.title}
          type="Document"
          content={message.documentPreview.content}
        />
      )}
      <div className="message-text">{message.content}</div>
    </div>
    {message.role === 'user' && (
      <div className="message-avatar user-avatar">
        <UserRound color='#00005f' size={20} />
      </div>
    )}
  </div>
);




return (
  <div className={`dax-ai-container ${isSidebarPinned ? 'sidebar-pinned' : 'sidebar-unpinned'}`}>
      <div ref={sidebarRef} className={`dax-chat-sidebar ${isSidebarPinned ? 'pinned' : 'unpinned'} ${isSidebarVisible ? 'visible' : ''}`}>
      <div className="sidebar-header">
          <h1>Recent Chats</h1>
          <button className="toggle-sidebar" onClick={toggleSidebarPin}>
            {isSidebarPinned ? <ArrowLeftToLine size={18} /> : <ArrowRightToLine size={18} />}
          </button>
        </div>
           <button onClick={handleNewChat} className="new-dax-chat-btn">Start new chat</button>
            <div className="dax-chat-list">
            {chats.map((chat) => (
                  <div 
              key={chat.id} 
              className={`dax-chat-item ${currentChat && chat.id === currentChat.id ? 'active' : ''} ${activeOptionsMenu === chat.id ? 'options-active' : ''}`}
              onClick={() => handleChatItemClick(chat)}
              onDoubleClick={() => handleChatItemDoubleClick(chat)}
              onMouseEnter={() => handleChatHover(chat.id)}
              onMouseLeave={handleChatLeave}
                 >
              <div className="dax-chat-item-content">
                {renamingChat === chat.id ? (
                  <form onSubmit={(e) => handleRenameSubmit(e, chat.id)}>
                    <input
                      type="text"
                      value={newChatName}
                      onChange={(e) => setNewChatName(e.target.value)}
                      onBlur={() => handleRenameSubmit({ preventDefault: () => {} }, chat.id)}
                      autoFocus
                    />
                  </form>
                ) : (
                  truncateChatName(chat.name)
                )}
          </div>
              {(hoveredChat === chat.id || activeOptionsMenu === chat.id) && !renamingChat && (
                <button className="options-btn" onClick={(e) => handleOptionsClick(e, chat.id)}>
                  <MoreHorizontal size={16} />
                </button>
              )}
              {activeOptionsMenu === chat.id && (
                <div className="options-menu" ref={optionsMenuRef}>
                  <button onClick={() => handleShareChat(chat.id)}>
                    <Share size={16} /> Share
                  </button>
                  <button onClick={(e) => handleRenameClick(e, chat.id, chat.name)}>
                    <Pencil size={16} /> Rename
                  </button>
                  <button onClick={() => handleSaveChat(chat.id)}>
                    <Archive size={16} /> Save
                  </button>
                  <button onClick={() => handleDeleteChat(chat.id)} className="delete-btn">
                    <Trash2 size={16} /> Delete
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="dax-user-email">{userEmail}</div>
      </div>
      <div className="dax-chat-main-wrapper">
        <div className="dax-chat-main" ref={chatMainRef}>
          <div className="dax-chat-messages">
            {messages.length === 0 && <div></div>}
            {messages.map((message, index) => renderMessage(message, index))}
            {isGenerating && (
              <div className="message assistant">
                <div className="message-avatar assistant-avatar">DAX</div>
                <div className="message-content generating">{generatingText}</div>
              </div>
            )}
            {isThinking && !isGenerating && (
              <div className="message assistant">
                <div className="message-avatar assistant-avatar">DAX</div>
                <div className="message-content">DAX AI is thinking...</div>
              </div>
            )}
          </div>

          <div className="dax-chat-input-wrapper">
            {documentPreview && (
              <DocumentPreview
                title={documentPreview.title}
                type="Document"
                content={documentPreview.content}
                onClose={() => setDocumentPreview(null)}
              />
            )}
            <form onSubmit={handleSubmit} className="dax-chat-input-form">
              <div className="dax-chat-input-container">
                <input
                  type="text"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Ask anything ...."
                  className="dax-chat-input"
                  ref={inputRef}
                />
                <button type="submit" className="send-btn" disabled={isLoading || isGenerating}>
                  <ArrowUp className="send-icon"/>
                </button>
          </div>
        </form>
        <p className='dax-chat-input-p'>DAX AI can make mistakes. Please verify responses.</p>
      </div>
    </div>
    </div>
    </div>

    
  );
};

export default DaxAI;