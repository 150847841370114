import React, { useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import './EditorPage.css';

const Editor = () => {
  const [title, setTitle] = useState('');
  const [sections, setSections] = useState([{ subheader: '', paragraph: '' }]);
  const db = getFirestore();

  const handleSectionChange = (index, field, value) => {
    const newSections = [...sections];
    newSections[index][field] = value;
    setSections(newSections);
  };

  const addSection = () => {
    setSections([...sections, { subheader: '', paragraph: '' }]);
  };

  const formatUrlTitle = (title) => {
    return title.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedTitle = formatUrlTitle(title);
    const infoRef = doc(db, 'info', formattedTitle.toLowerCase());
    await setDoc(infoRef, {
      title,
      sections,
    });
    // Redirect to the new URL format
    window.location.href = `https://info.daxtop.com/${formattedTitle}`;
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Create Wikipedia-style Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        {sections.map((section, index) => (
          <div key={index} className="mb-4">
            <label className="block mb-2">Subheader:</label>
            <input
              type="text"
              value={section.subheader}
              onChange={(e) => handleSectionChange(index, 'subheader', e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <label className="block mb-2">Paragraph:</label>
            <textarea
              value={section.paragraph}
              onChange={(e) => handleSectionChange(index, 'paragraph', e.target.value)}
              className="w-full p-2 border rounded"
              rows="4"
            ></textarea>
          </div>
        ))}
        <button type="button" onClick={addSection} className="bg-blue-500 text-white p-2 rounded mr-2">
          Add Section
        </button>
        <button type="submit" className="bg-green-500 text-white p-2 rounded">
          Save Page
        </button>
      </form>
    </div>
  );
};

export default Editor;