import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot, updateDoc, doc, Timestamp } from 'firebase/firestore';
import ConfirmationDialog from './ConfirmationDialog';
import './Notifications.css';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPostponeAlert, setShowPostponeAlert] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchBrandName(user.uid);
    }
  }, []);
  
  useEffect(() => {
    if (modifiedBrandName) {
      const notificationsRef = collection(db, `stores/${modifiedBrandName}/notifications`);
      const q = query(notificationsRef);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const now = new Date();
        const notificationsData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(notification => 
            notification.statusPro !== 'Dismissed' &&
            (notification.statusPro !== 'Postponed' || 
            (notification.postponedUntil && notification.postponedUntil.toDate() <= now))
          )
          .sort((a, b) => b.date.toDate() - a.date.toDate());
        setNotifications(notificationsData);
        setLoading(false);
  
        // Mark all notifications as seen
        notificationsData.forEach(notification => {
          if (!notification.seen) {
            updateDoc(doc(db, `stores/${modifiedBrandName}/notifications`, notification.id), { seen: true });
          }
        });
      });

      
    return () => unsubscribe();
  }
}, [modifiedBrandName]);


const handlePostpone = async (notification) => {
  try {
    const postponeDate = new Date();
    postponeDate.setHours(postponeDate.getHours() + 24);
    await updateDoc(doc(db, `stores/${modifiedBrandName}/notifications`, notification.id), {
      postponedUntil: Timestamp.fromDate(postponeDate),
      statusPro: 'Postponed'
    });
    setNotifications(prevNotifications => 
      prevNotifications.filter(n => n.id !== notification.id)
    );
    setShowPostponeAlert(true);
  } catch (error) {
    console.error('Error postponing notification:', error);
  }
};

const fetchBrandName = async (userId) => {
  const storesRef = collection(db, 'stores');
  const q = query(storesRef, where('userId', '==', userId));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    if (!snapshot.empty) {
      setBrandName(snapshot.docs[0].data().brandName);
      setModifiedBrandName(snapshot.docs[0].data().modifiedBrandName);
    }
  });

  return () => unsubscribe();
};


  const handleDismiss = (notification) => {
    setSelectedNotification(notification);
    setShowConfirmation(true);
  };

  const confirmDismiss = async () => {
    setShowConfirmation(false);
    if (selectedNotification) {
      try {
        await updateDoc(doc(db, `stores/${modifiedBrandName}/notifications`, selectedNotification.id), {
          statusPro: 'Dismissed'
        });
      } catch (error) {
        console.error('Error dismissing notification:', error);
      }
    }
    setSelectedNotification(null);
  };
  

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  return (
    <div className="notifications-container-main">
    <div className="notifications-container">
      <h2>Notifications ({notifications.length})</h2>
      {notifications.length === 0 ? (
        <p>No notifications</p>
      ) : (  
        <ul className="notifications-list">
          {notifications.map((notification) => (
            <li key={notification.id} className={`notification-item ${notification.status ? notification.status.toLowerCase().replace(/\s+/g, '-') : ''} ${notification.title === 'Welcome' ? 'welcome-notification' : ''}`}>
              <h3>{notification.title}</h3>
              {notification.title === 'Welcome' ? (
                <div className="welcome-content">
                  <p>{notification.message}</p>
                  <p className="welcome-date">Date: {notification.date && notification.date.toDate ? notification.date.toDate().toLocaleString() : 'N/A'}</p>
                </div>
              ) : (
                <>
                  <p>Store Address: {notification.store}</p>
                  <p>Item: {notification.item}</p>
                  <p>Current Quantity: {notification.quantity}</p>
                  <p>Status: {notification.status}</p>
                  <p>Date: {notification.date && notification.date.toDate ? notification.date.toDate().toLocaleString() : 'N/A'}</p>
                </>
              )}
              <button onClick={() => handleDismiss(notification)} className="dismiss-btn">Dismiss</button>
              {notification.title !== 'Welcome' && (
                <button onClick={() => handlePostpone(notification)} className="postpone-btn">Postpone</button>
              )}
            </li>
          ))}
        </ul>
      )}
     {showConfirmation && (
  <ConfirmationDialog
    message="Are you sure you want to dismiss?"
    onConfirm={confirmDismiss}
    onCancel={() => setShowConfirmation(false)}
    confirmText="Confirm"
    cancelText="Cancel"
  />
)}


      {showPostponeAlert && (
        <div className="confirmation-overlay">
          <div className="alert-dialog">
            <div className="alert-content">
              <p>Notification will arrive in 24 hours!</p>
              <button onClick={() => setShowPostponeAlert(false)} className="ok-btn">OK</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Notifications;