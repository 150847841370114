import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import './Reports.css';
import { SimpleLoad } from './LoadingPages/LoadingAnimation';
import { useLoading } from './LoadingContext';

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSnapshots, setPageSnapshots] = useState([]);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const { setPageLoading } = useLoading();

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    fetchBrandName();
  }, []);
  
  useEffect(() => {
    if (modifiedBrandName) {
      fetchReports(currentPage);
    }
  }, [modifiedBrandName, pageSize, currentPage]);

  // Update loading state in context whenever local loading state changes
  useEffect(() => {
    setPageLoading(loading);
  }, [loading]);
  
  const fetchBrandName = async () => {
    setLoading(true);
    setPageLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const storeDoc = await getDocs(query(collection(db, 'stores'), where('userId', '==', user.uid)));
        if (!storeDoc.empty) {
          setBrandName(storeDoc.docs[0].data().brandName);
          setModifiedBrandName(storeDoc.docs[0].data().modifiedBrandName);
        }
      }
    } catch (err) {
      setError('Failed to fetch brand name.');
    }
  };
  
  const fetchReports = async (page) => {
    setLoading(true);
    setPageLoading(true);
    try {
      let q;
      let startAtSnapshot = null;
      
      if (pageSnapshots[page - 1]) {
        startAtSnapshot = pageSnapshots[page - 1];
      }
      if (page === 1) {
        q = query(
          collection(db, `stores/${modifiedBrandName}/reports`),
          orderBy('timestamp', 'desc'),
          limit(pageSize)
        );
      } else {
        q = query(
          collection(db, `stores/${modifiedBrandName}/reports`),
          orderBy('timestamp', 'desc'),
          startAfter(startAtSnapshot),
          limit(pageSize)
        );
      }
  
      const querySnapshot = await getDocs(q);
      const fetchedReports = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate()
      }));
      setReports(fetchedReports);
      if (!pageSnapshots[page]) {
        setPageSnapshots(prev => {
          const newSnapshots = [...prev];
          newSnapshots[page] = querySnapshot.docs[querySnapshot.docs.length - 1];
          return newSnapshots;
        });
      }
      if (page === 1) {
        const totalQuery = query(collection(db, `stores/${modifiedBrandName}/reports`));
        const totalSnapshot = await getDocs(totalQuery);
        setTotalReports(totalSnapshot.size);
      }
    } catch (err) {
      setError('Failed to fetch reports.');
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  };

  const handlePageSizeChange = (e) => {
    setLoading(true);
    setPageLoading(true);
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
    setPageSnapshots([]);
  };

  const handleNextPage = () => {
    setLoading(true);
    setPageLoading(true);
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setLoading(true);
    setPageLoading(true);
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handlePageNumberChange = (e) => {
    setPageNumber(e.target.value);
  };

  const handlePageNumberSubmit = () => {
    const page = parseInt(pageNumber, 10);
    if (page > 0 && page <= totalPages) {
      setLoading(true);
      setPageLoading(true);
      setCurrentPage(page);
    }
  };

  const totalPages = Math.ceil(totalReports / pageSize);

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Action", "Item", "Brand" , "Quantity", "Store", "Date"];
    const tableRows = [];

    reports.forEach(report => {
      const reportData = [
        truncateText(report.action),
        truncateText(report.itemName || report.item),
        truncateText(report.brand),
        truncateText(report.quantity !== undefined ? report.quantity.toString() : '-'),
        truncateText(report.store),
        truncateText(report.timestamp.toLocaleString())
      ];
      tableRows.push(reportData);
    });

    doc.autoTable(tableColumn, tableRows);
    doc.save("report.pdf");
  };

  const truncateText = (text, length = 20) => {
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };

  if (loading) {
    return <SimpleLoad onLoadComplete={() => {
      setLoading(false);
      setPageLoading(false);
    }} />;
  }
  if (error) return <div>{error}</div>;

  return (
    <div className='reports-container-main'>
    <div className="reports-container">
      <div className="reports-container-header">
      <h1 className="reports-header" >Reports</h1>
      </div>
      <hr className='reports-container-hr'/>

      <div className="reports-controls">
        <div className="page-size-control">
          <label htmlFor="pageSize">Show:</label>
          <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
            <option value={totalReports}>All</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <button className="download-btn" onClick={downloadPDF}>Download PDF</button>
      </div>
      <div className="reports-table-container">
        <table className="reports-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Item</th>
              <th>Brand</th>
              <th>Quantity</th>
              <th>Store</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => (
              <tr key={index}>
                <td>{truncateText(report.action)}</td>
                <td>{truncateText(report.itemName || report.item)}</td>
                <td>{truncateText(report.brand)}</td>
                <td>{truncateText(report.quantity !== undefined ? report.quantity : '-')}</td>
                <td>{truncateText(report.store)}</td>
                <td>{truncateText(report.timestamp.toLocaleString())}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="reports-pagination">
        <button className="reports-pagination-button" onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
        <span className="reports-pagination-span" >{`${currentPage} of ${totalPages}`}</span>
        <button className="reports-pagination-button" onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
        <input
          className="reports-pagination-input"
          type="number"
          value={pageNumber}
          onChange={handlePageNumberChange}
          min={1}
          max={totalPages}
          onBlur={handlePageNumberSubmit}
        />
        <button className="reports-pagination-button" onClick={handlePageNumberSubmit}>Go</button>
      </div>
    </div>

    </div>
  );
};

export default Reports;
