// SideBar.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, Box, Edit, ShoppingCart, FileText, User, Bell, Bot, ShoppingBag } from 'lucide-react';

const Sidebar = ({ notificationCount }) => {
  const location = useLocation();
  const navigate = useNavigate();


  const isActive = (path) => {
    if (path === '/inventory/dax') {
      return location.pathname.startsWith('/inventory/dax') ? 'active' : '';
    }
    return location.pathname === path ? 'active' : '';
  };

  const navLinks = [
    { path: '/inventory/dashboard', icon: Home, label: 'Dashboard' },
    { path: '/inventory/item-storage', icon: Box, label: 'Inventory' },
    { path: '/inventory/item-add', icon: Edit, label: 'Add' },
    { path: '/inventory/takeout', icon: ShoppingCart, label: 'Take Out' },
    { path: '/inventory/orders', icon: ShoppingBag, label: 'Orders' },
    { path: '/inventory/reports', icon: FileText, label: 'Reports' },
    { path: '/inventory/profile', icon: User, label: 'Profile' },
    { path: '/inventory/dax/new', icon: Bot, label: 'DaxAI Chat' }
    // { path: '/inventory/notifications', icon: Bell, label: 'Notifications', count: notificationCount },
  ];


  const handleFinancesClick = () => {
    navigate('/inventory/finances/accounting');
  };

  return (
    <div className="sidebar-main">
      <ul className="sidebar-main-links">
        {navLinks.map((link) => (
          <li key={link.path}>
            <Link to={link.path} className={isActive(link.path)}>
              <link.icon size={20} />
              <span>{link.label}</span>
              {link.count > 0 && (
                <span className="notification-count">
                  {link.count > 99 ? '99+' : link.count}
                </span>
              )}
            </Link>
          </li>
        ))}
          <button className="finances-btn" onClick={handleFinancesClick}>Finances</button>
      </ul>
    </div>
  );
};

export default Sidebar;