import React from 'react';
import { NavLink } from 'react-router-dom';
import { MessageSquare, Inbox, SendHorizontal, FileEdit, AlertTriangle, Search, Settings } from 'lucide-react';

const CommunicationSidebar = () => {
  const sidebarLinks = [
    { path: '/inventory/messenger', icon: MessageSquare, label: 'Chat' },
    { path: '/inventory/inbox', icon: Inbox, label: 'Inbox' },
    { path: '/inventory/sent', icon: SendHorizontal, label: 'Sent' },
    { path: '/inventory/drafts', icon: FileEdit, label: 'Drafts' },
    { path: '/inventory/spam', icon: AlertTriangle, label: 'Spam' },
    { path: '/inventory/settings', icon: Settings, label: 'Settings' }

  ];

  return (
    <div className="sidebar">
      <div className="messenger-search-input-sidebar-wrapper">
        <div className="messenger-search-container">
          <Search className="messenger-search-icon" />
          <input
            type="text"
            placeholder="Search..."
            className="messenger-search-input-sidebar"
          />
        </div>
      </div>
      <nav className="sidebar-navigation">
        {sidebarLinks.map((link) => (
          <NavLink
            key={link.path}
            to={link.path}
            className={({ isActive }) =>
              `sidebar-link ${isActive ? 'active' : ''}`
            }
          >
            <link.icon className="sidebar-icon" />
            <span>{link.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default CommunicationSidebar;
