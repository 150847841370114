import React, { useState, useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, updateDoc, doc, addDoc, runTransaction, getDoc, setDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import ConfirmationDialog from './ConfirmationDialog';

const TakeOut = () => {
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [takeOutAmount, setTakeOutAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  const auth = getAuth();
  const db = getFirestore();
  const location = useLocation();
  const takeOutInputRef = useRef(null);

  useEffect(() => {
    fetchBrandNameAndStores();
  }, []);

  useEffect(() => {
    if (location.state && location.state.modifiedBrandName) {
      setModifiedBrandName(location.state.modifiedBrandName);
      setSelectedStore(location.state.selectedStore);
      setSelectedItem(location.state.selectedItem);
    } else {
      fetchBrandNameAndStores();
    }
  }, [location]);

  useEffect(() => {
    if (brandName && selectedStore) {
      fetchItems();
    }
  }, [brandName, selectedStore]);

  useEffect(() => {
    if (selectedItem) {
      const item = items.find(i => i.id === selectedItem);
      if (item) {
        setMaxAmount(item.quantity);
      }
    }
  }, [selectedItem, items]);

  useEffect(() => {
    if (selectedStore && selectedItem && takeOutInputRef.current) {
      takeOutInputRef.current.focus();
    }
  }, [selectedStore, selectedItem]);

  const fetchBrandNameAndStores = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const storeDoc = await getDocs(query(collection(db, 'stores'), where('userId', '==', user.uid)));
        if (!storeDoc.empty) {
          const storeData = storeDoc.docs[0].data();
          setBrandName(storeData.brandName);
          setModifiedBrandName(storeData.modifiedBrandName);
          
          const networkStoresSnapshot = await getDocs(collection(db, `stores/${storeData.modifiedBrandName}/networkStores`));
          const storeAddresses = networkStoresSnapshot.docs.map(doc => doc.id);
          setStores(storeAddresses);
        }
      }
    } catch (error) {
      console.error("Error fetching brand name and stores:", error);
    }
  };

  const fetchItems = async () => {
    if (!modifiedBrandName || !selectedStore) {
      console.error("Modified brand name or selected store is not set");
      return;
    }
    try {
      const q = query(collection(db, `stores/${modifiedBrandName}/networkStores/${selectedStore}/items`));
      const querySnapshot = await getDocs(q);
      const fetchedItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(fetchedItems);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    setSelectedItem('');
    setTakeOutAmount('');
    
    if (selectedItem && takeOutAmount) {
      const item = items.find(i => i.id === selectedItem);
      if (item && item.quantity >= parseInt(takeOutAmount)) {
        try {
          await runTransaction(db, async (transaction) => {
            const itemRef = doc(db, `stores/${modifiedBrandName}/networkStores/${selectedStore}/items`, selectedItem);  
            const itemDoc = await transaction.get(itemRef);
  
            if (!itemDoc.exists()) {
              throw "Item does not exist!";
            }
  
            const newQuantity = itemDoc.data().quantity - parseInt(takeOutAmount);
  
            if (newQuantity < 0) {
              throw "Not enough items in stock";
            }
  
            transaction.update(itemRef, { quantity: newQuantity });
  
            await addDoc(collection(db, `stores/${modifiedBrandName}/reports`), {
              action: 'Taken Out',
              brand: item.brand,
              itemName: item.name,
              quantity: parseInt(takeOutAmount),
              store: selectedStore,
              timestamp: new Date()
            });
  
            await checkAndSendNotifications({
              ...item,
              quantity: newQuantity
            }, modifiedBrandName, selectedStore);
          });
    
          fetchItems();
        } catch (error) {
          console.error("Transaction failed: ", error);
          alert(error);
        }
      } else {
        alert('Not enough items in stock');
      }
    }
  };

  const checkAndSendNotifications = async (item, modifiedBrandName, store) => {
    const today = new Date();
    const notifications = [];

    if (item.quantity === 0) {
      notifications.push({
        id: item.id,
        title: 'Not Available',
        store: store,
        item: item.name,
        quantity: item.quantity,
        status: 'Not Available',
        date: today,
        statusPro: ''
      });
    }

    if (item.quantity < item.lowStockThreshold && item.quantity > 0) {
      notifications.push({
        id: item.id,
        title: 'Low Stock Threshold',
        store: store,
        item: item.name,
        quantity: item.quantity,
        status: 'Low Stock Threshold',
        date: today,
        statusPro: ''
      });
    }

    for (const notification of notifications) {
      try {
        await setDoc(doc(db, `stores/${modifiedBrandName}/notifications`, notification.id), notification);
      } catch (error) {
        console.error('Error sending notification:', error);
      }
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleTakeOut = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const isFormValid = selectedStore && selectedItem && takeOutAmount;

  return (
    <div className="item-add-container">
    <div className="itemadd-container">
      <h2 className="itemadd-title">Take Out Items</h2>
      <form onSubmit={handleTakeOut} className="itemadd-form">
        <div className="itemadd-form-group">
          <select 
            className="itemadd-form-group-input-i"
            value={selectedStore} 
            onChange={(e) => setSelectedStore(e.target.value)}
          >
            <option value="">Select a Store</option>
            {stores.map(store => (
              <option key={store} value={store}>{store}</option>
            ))}
          </select>
        </div>
        <div className="itemadd-form-group">
          <select 
            className="itemadd-form-group-input-i"
            value={selectedItem} 
            onChange={(e) => setSelectedItem(e.target.value)}
          >
            <option value="">Select an item</option>
            {items.map(item => (
              <option key={item.id} value={item.id}>{item.name} - Current quantity: {item.quantity}</option>
            ))}
          </select>
        </div>
        <div className="itemadd-form-group">
          <input
            ref={takeOutInputRef}
            className="itemadd-form-group-input-i"
            type="number"
            value={takeOutAmount}
            onChange={(e) => setTakeOutAmount(e.target.value)}
            placeholder="Amount to take out"
            min="1"
            max={maxAmount}
          />
        </div>
        
        <button
          type="submit"
          className="itemadd-submit-btn"
          disabled={!isFormValid}
        >
          Take Out
        </button>
        
      </form>

      {showConfirmation && (
       <ConfirmationDialog
       message="Are you sure you want to proceed?"
       onConfirm={handleConfirm}
       onCancel={() => setShowConfirmation(false)}
       confirmText="Take Out"
       cancelText="Cancel"
     />
    )}
    </div>
    </div>
  );
};

export default TakeOut;