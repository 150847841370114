import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import {Bell, Menu, X, Search, ChevronRight, Bot, BotOff, ShoppingBag, MessageCircle, Mail } from 'lucide-react';
import './NavBar.css';
import { translations } from './Data/data';
import logoInventory from './Images/Logos/Daxtop-Inventory-logo.svg';
import logoMailbox from './Images/Logos/Daxtop-Mailbox-logo.svg'
import MobileMenuNavBar from './MobileMenuNavBar.js';
import Sidebar from './Sidebar.js'; 
import CommunicationSidebar from './Communication/CommunicationSidebar.js';
import { useDaxHover } from './DaxHoverContext.js';

const NavBar = () => {
  const auth = getAuth();
  const db = getFirestore();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0); 
  const [brandName, setBrandName] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [language, setLanguage] = useState('en');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPageLoading, setIsPageLoading] = useState(true);
  const { isDaxEnabled, setIsDaxEnabled } = useDaxHover();
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const isMailboxPath = location.pathname.startsWith('/inventory/messenger') ||
    location.pathname.startsWith('/inventory/inbox') ||
    location.pathname.startsWith('/inventory/sent') ||
    location.pathname.startsWith('/inventory/drafts') ||
    location.pathname.startsWith('/inventory/spam') ||
    location.pathname.startsWith('/inventory/settings');

  const isMessengerChat = location.pathname.match(/^\/inventory\/messenger\/[^/]+$/);




  
  useEffect(() => {
    // Reset loading state on route change
    setIsPageLoading(true);

    // Listen for loading status changes from the page components
    const handleLoadingChange = (event) => {
      if (event.detail.page === location.pathname) {
        setIsPageLoading(event.detail.isLoading);
      }
    };

    window.addEventListener('pageLoadingChange', handleLoadingChange);

    return () => {
      window.removeEventListener('pageLoadingChange', handleLoadingChange);
    };
  }, [location.pathname]);

  const isActive = (path) => {
    if (path === '/inventory/dax') {
      return location.pathname.startsWith('/inventory/dax') ? 'active' : '';
    }
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  const handleDaxAIClick = () => {
    if (!isDaxEnabled) {
      setIsDaxEnabled(true);
    } else {
      setIsDaxEnabled(false);
    }
  };
  


  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchBrandName(user.uid);
    }
  }, []);

  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
      if (window.innerWidth > 1200) {
        setIsMobileMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchBrandName = async (userId) => {
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const storeData = snapshot.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);
      }
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (modifiedBrandName) {
      const notificationsRef = collection(db, `stores/${modifiedBrandName}/notifications`);
      const q = query(notificationsRef, where('statusPro', 'in', ['', 'Postponed']));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const now = new Date();
        const activeNotifications = snapshot.docs.filter(doc => {
          const data = doc.data();
          return (data.statusPro !== 'Postponed' || 
            (data.postponedUntil && data.postponedUntil.toDate() <= now)) &&
            !data.seen;
        });
        setNotificationCount(activeNotifications.length);
      });

      return () => unsubscribe();
    }
  }, [brandName]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  useEffect(() => {
    if (modifiedBrandName) {
      const messengerRef = collection(db, `stores/${modifiedBrandName}/messenger`);
      
      const unsubscribe = onSnapshot(
        messengerRef,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!snapshot.metadata.hasPendingWrites) {
            const totalUnread = snapshot.docs.reduce((total, doc) => {
              const data = doc.data();
              return total + (data.unreadMessageCount || 0);
            }, 0);
            
            setUnreadMessageCount(totalUnread);
          }
        }
      );
  
      return () => unsubscribe();
    }
  }, [modifiedBrandName]);  

  const navLinks = [
    { path: '/inventory/notifications', icon: Bell, count: notificationCount },
    { path: '/inventory/messenger', icon: Mail, label: 'Messenger', count: unreadMessageCount }
  ];

  const showNavBar = !location.pathname.includes('/inventory/finances/');
  const showDaxButton = !location.pathname.startsWith('/inventory/dax') && !isMessengerChat;
  
  
  const loadingProtectedRoutes = [
    '/inventory/dashboard',
    '/inventory/reports',
    '/inventory/profile'
  ];

  const isLoadingProtectedRoute = loadingProtectedRoutes.includes(location.pathname);

  const hideSidebar = [
    '/inventory/dax',
    '/inventory/notifications',
    '/inventory/messenger',
    '/inventory/inbox',
    '/inventory/sent',
    '/inventory/drafts',
    '/inventory/spam',
    '/inventory/settings'
  ].some(path => location.pathname.startsWith(path));



  
  return (
    <div className="App">
      {showNavBar && (
        <nav className={`navbar ${isMobileView ? 'mobile-view' : ''}`}>
          <div className="navbar-container">
            <div className="navbar-left">
              <div className="navbar-logo">
                <Link to="/inventory/dashboard">
                  <img src={isMailboxPath ? logoMailbox : logoInventory} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="navbar-right">   
              {!isMobileView && (
                <ul className="navbar-links">
                  {navLinks.map((link) => (
                    <li key={link.path}>
                      <Link to={link.path === '/inventory/dax' ? '/inventory/dax/new' : link.path} className={isActive(link.path)}>
                        <link.icon size={19} />
                        {link.count > 0 && (
                          <span className="notification-count">
                            {link.count > 99 ? '99+' : link.count}
                          </span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              )} 

              {isMobileView && (
                <>

                  <div>                
            <ul className="navbar-links">
                  {navLinks.map((link) => (
                    <li key={link.path}>
                      <Link to={link.path === '/inventory/dax' ? '/inventory/dax/new' : link.path} className={isActive(link.path)}>
                        <link.icon size={19} />
                        {link.count > 0 && (
                          <span className="notification-count">
                            {link.count > 99 ? '99+' : link.count}
                          </span>
                        )}
                        
                      </Link>
                      
                    </li>
                    
                  ))}
                <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                  <Menu size={20.5} />
                </button>

                </ul>
                </div>

                  
                
                </>
              )}
            </div>
          </div>

          <MobileMenuNavBar
            isOpen={isMobileMenuOpen}
            onClose={toggleMobileMenu}
            translate={translate}
            notificationCount={notificationCount}
          />

        {showDaxButton && (
                    <div 
                      className={`dax-ai-button ${isDaxEnabled ? 'enabled' : ''}`}
                      onClick={handleDaxAIClick}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <p>{isDaxEnabled && isHovered ? 'Click to Disable' : (isDaxEnabled ? 'DaxAI Enabled' : 'DaxAI')}</p>
                      {isDaxEnabled && isHovered ? <BotOff size={22} /> : <Bot size={22} />}
                    </div>
                )}
        </nav>
      )}
           <div className="main-content-sidebar">
        {!hideSidebar && (!isLoadingProtectedRoute || !isPageLoading) && (
          <Sidebar notificationCount={notificationCount} />
        )}
      </div>

    </div>
  );
};

export default NavBar;